export default {
  metadata: {
    news: {
      categories: [
        {
          id: "technology",
          name: "Công nghệ",
        },
        {
          id: "business",
          name: "Kinh doanh",
        },
        {
          id: "world",
          name: "Thế giới",
        },
        {
          id: "sports",
          name: "Thể thao",
        },
        {
          id: "auto",
          name: "Xe",
        },
      ],
      publishers: [
        //"Football Italia",
        //"Football.London",
        "VnExpress",
        "VTC",
        "VOV",
        "TuoiTre",
        "BongDaSo",
        "TheThao247",
      ],
      keywords: ["Golf", "Ukraine"],
    },
    newspicks: {
      categories: [
        {
          id: "technology",
          name: "テクノロジ",
        },
        {
          id: "business",
          name: "ビジネス",
        },
        {
          id: "economic",
          name: "政治・経済",
        },
        {
          id: "market",
          name: "金融・マーケット",
        },
      ],
      keywords: [
        "ソフトバンクグループ",
        "トヨタ",
        "ゴルフ",
        "ウクライナ",
        "イスラエル",
      ],
    },
    yahoo: {
      categories: [
        {
          id: "world",
          name: "国際",
        },
        {
          id: "technology",
          name: "テクノロジ",
        },
        {
          id: "business",
          name: "経済",
        },
      ],
      keywords: [
        "ソフトバンクグループ",
        "ゴルフ",
        "アルファード",
        "ハイエース",
        "キャラバン",
        "車中泊",
        "ベトナム",
        "ウクライナ",
      ],
    },
  },
  mqtt: {
    topic: "thangiswho/mynews-5hxkd9gjtkxw",
    options: {
      url: `ws://broker.emqx.io:8083/mqtt`,
      clientId: "emqx_react_" + Math.random().toString(16).substring(2, 8),
      // username: "emqx", // "thangiswho",
      // password: "public", // "kh0ngc0p11s",

      clean: true,
      //reconnectPeriod: 1000, // ms
      connectTimeout: 30 * 1000, // ms
      debug: true,
    },
  },
};
